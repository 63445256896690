import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import {
  Drawer,
  Toolbar,
  Box,
  List,
  SvgIcon,
  Hidden,
  Divider,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import {
  toggleSidebar,
  selectNavigationSidebarOpen,
  selectCurrentSeason,
} from './navigationSlice';

import SeasonSelector from "./SeasonSelector";

import routes, {
  HOME,
  RANKS,
  SEASON_PREDICTIONS,
  STANDINGS,
  WEEKLY_PREDICTIONS,
  MY_ENTRY,
  SEASONS,
} from "../../data/routes";

import SidebarLink from "../../components/SidebarLink";

import { ReactComponent as RoseIcon } from "../../assets/icons/rose-flower-outlined.svg";

const drawerWidth = 240;

const styles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    border: 0,
  },
  drawerContainer: {
    overflow: 'auto',
  },
});

const folderData = {
  "ENTRY": {
    sidebarText: "My Entry",
    icon: <SvgIcon component={RoseIcon} htmlColor="white" viewBox="0 0 512 512"/>,
  },
};

const routesBySeasonId = {
  "michelle_2021": [
    routes[HOME],
    routes[MY_ENTRY],
    routes[STANDINGS],
  ],
  "katie_2021": [
    routes[HOME],
    routes[MY_ENTRY],
    routes[STANDINGS],
  ],
  "clayton_2022": [
    routes[HOME],
    routes[MY_ENTRY],
    routes[STANDINGS],
  ],
  "gabby_rachel_2022": [
    routes[HOME],
    // routes[MY_ENTRY],
    // routes[STANDINGS],
  ],
  "zach_2023": [
    routes[HOME],
    // routes[MY_ENTRY],
    // routes[STANDINGS],
  ],
  "charity_2023": [
    routes[HOME],
    // routes[MY_ENTRY],
    // routes[STANDINGS],
  ],
  "golden_gerry": [
    routes[HOME],
    // routes[MY_ENTRY],
    // routes[WEEKLY_PREDICTIONS],
    // routes[STANDINGS],
  ],
  "joey_2024": [
    routes[HOME],
    routes[MY_ENTRY],
    routes[STANDINGS],
  ],
  "jenn_2024": [
    routes[HOME],
    routes[MY_ENTRY],
    routes[WEEKLY_PREDICTIONS],
    routes[STANDINGS],
  ],
  "golden_joan": [
    routes[HOME],
    routes[MY_ENTRY],
    routes[WEEKLY_PREDICTIONS],
    routes[STANDINGS],
  ],
  "grant_2025": [
    routes[HOME],
    routes[MY_ENTRY],
    routes[WEEKLY_PREDICTIONS],
    routes[STANDINGS],
  ],
};

class Sidebar extends Component {
  toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.props.dispatch(toggleSidebar())
  };

  render() {
    // Styling
    const {
      classes,
      user,
      sidebarOpen,
      currentSeason,
    } = this.props;

    const baseUrl = routes[SEASONS].pathWithoutParam + currentSeason;

    return (
      <Box>
        <Hidden mdDown>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }} 
          >
            <Toolbar />
            <Box >
              <SeasonSelector />
            </Box>
            <Box mr={2} className={classes.drawerContainer}>
              <List>
                {routesBySeasonId[currentSeason].map((route) => (
                  <SidebarLink
                    key={route.name}
                    baseUrl={baseUrl}
                    routeData={route}
                  />
                ))}
              </List>
            </Box>
          </Drawer>
        </Hidden>
        <Hidden lgUp>
          <Drawer
            variant="temporary"
            classes={{
              paper: classes.drawerPaper,
            }} 
            open={sidebarOpen}
            onClose={this.toggleDrawer()}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Toolbar disableGutters>
              <Box width="100%">
                <SeasonSelector />
              </Box>
            </Toolbar>
            <Divider />
            <Box
              mr={2}
              className={classes.drawerContainer}
              onClick={this.toggleDrawer()}
              onKeyDown={this.toggleDrawer()}
            >
              <List>
                {routesBySeasonId[currentSeason].map((route) => (
                  <SidebarLink
                    key={route.name}
                    baseUrl={baseUrl}
                    routeData={route}
                  />
                ))}
              </List>
            </Box>
          </Drawer>
        </Hidden>
      </Box>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    sidebarOpen: selectNavigationSidebarOpen(state),
    currentSeason: selectCurrentSeason(state),
  };
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps),
)(Sidebar);
