import React, { Component } from "react";

import { compose } from 'redux'

import { connect } from 'react-redux'

import { withRouter, matchPath, } from "react-router-dom";

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  withWidth,
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";

import {
  changeCurrentSeason,
  selectCurrentSeason,
  toggleSidebar,
} from './navigationSlice';

const styles = (theme) => ({
});

const seasonsById = {
  "grant_2025": {
    id: "grant_2025",
    label: "Grant",
    imageUrl: "grant_2025.jpg",
  },
  "golden_joan": {
    id: "golden_joan",
    label: "Joan",
    imageUrl: "joan_2024.jpg",
  },
  "jenn_2024": {
    id: "jenn_2024",
    label: "Jenn",
    imageUrl: "jenn_2024.jpeg",
  },
  "joey_2024": {
    id: "joey_2024",
    label: "Joey",
    imageUrl: "joey_2024.jpeg",
  },
  "golden_gerry": {
    id: "golden_gerry",
    label: "Gerry",
    imageUrl: "golden_gerry.jpeg",
  },
  "charity_2023": {
    id: "charity_2023",
    label: "Charity",
    imageUrl: "charity_2023.jpeg",
  },
  "zach_2023": {
    id: "zach_2023",
    label: "Zach",
    imageUrl: "zach_2023.jpeg",
  },
  "gabby_rachel_2022": {
    id: "gabby_rachel_2022",
    label: "Gabby & Rachel",
    imageUrl: "gabby_rachel_2022.jpeg",
  },
  "clayton_2022": {
    id: "clayton_2022",
    label: "Clayton",
    imageUrl: "clayton_2022.jpeg",
  },
  "michelle_2021": {
    id: "michelle_2021",
    label: "Michelle",
    imageUrl: "michelle_fall_2021.jpeg",
  },
  "katie_2021": {
    id: "katie_2021",
    label: "Katie",
    imageUrl: "katie_summer_2021.jpeg",
  },
};

//const bacheloretteSeasons = ["charity_2023", "gabby_rachel_2022", "michelle_2021", "katie_2021"];
const bacheloretteSeasons = ["jenn_2024", "michelle_2021", "katie_2021"];
// const bachelorSeasons = ["joey_2024", "zach_2023", "clayton_2022"];
const bachelorSeasons = ["grant_2025", "joey_2024", "clayton_2022"];
// const goldenBachelorSeasons = ["golden_gerry"];
const goldenBachelorSeasons = [];
const goldenBacheloretteSeasons = ["golden_joan"];
// const allSeasonIds = ["joey_2024", "golden_gerry", "charity_2023", "zach_2023", "gabby_rachel_2022", "clayton_2022", "michelle_2021", "katie_2021"];
const allSeasonIds = ["grant_2025", "golden_joan", "jenn_2024", "joey_2024", "clayton_2022", "michelle_2021", "katie_2021"];

class SeasonSelector extends Component {
  selectSeason = (open) => (event) => {
    if (event.target.value) {
      this.props.dispatch(changeCurrentSeason(event.target.value))
      this.props.history.push(`/seasons/${event.target.value}`);

      const isSmallScreen = /xs|sm|md/.test(this.props.width);
      if (isSmallScreen) {
        this.props.dispatch(toggleSidebar());
      }
    }
  };

  changeCurrentSeasonToSeasonInUrl = (url) => {
    const currentSeason = allSeasonIds.filter((id) => url.includes(id))[0];
    if (this.props.currentSeason !== currentSeason) {
      this.props.dispatch(changeCurrentSeason(currentSeason))
    }
  }

  componentDidMount() {
    this.changeCurrentSeasonToSeasonInUrl(this.props.location.pathname);
  };

  render() {
    const {
      currentSeason,
    } = this.props;

    return (
      <FormControl
        fullWidth
        variant="filled"
      >
        <InputLabel id="season-select-label">Current Season</InputLabel>
        <Select
          labelId="season-select-label"
          id="season-select"
          value={currentSeason}
          label="Current Season"
          onChange={this.selectSeason()}
          renderValue={selected => seasonsById[selected].label}
        >
          <ListSubheader>Bachelorette</ListSubheader>
          {bacheloretteSeasons.map((seasonId) => {
            const season = seasonsById[seasonId];

            return (
              <MenuItem
                key={season.id}
                value={season.id}
              >
                <ListItemAvatar>
                  <Avatar
                    src={`/images/${season.imageUrl}`}
                  />
                </ListItemAvatar>
                <ListItemText primary={season.label} />
              </MenuItem>
            );
          })}
          <ListSubheader>Bachelor</ListSubheader>
          {bachelorSeasons.map((seasonId) => {
            const season = seasonsById[seasonId];

            return (
              <MenuItem
                key={season.id}
                value={season.id}
              >
                <ListItemAvatar>
                  <Avatar
                    src={`/images/${season.imageUrl}`}
                  />
                </ListItemAvatar>
                <ListItemText primary={season.label} />
              </MenuItem>
            );
          })}
          {/* <ListSubheader>Golden Bachelor</ListSubheader> */}
          {goldenBachelorSeasons.map((seasonId) => {
            const season = seasonsById[seasonId];

            return (
              <MenuItem
                key={season.id}
                value={season.id}
              >
                <ListItemAvatar>
                  <Avatar
                    src={`/images/${season.imageUrl}`}
                  />
                </ListItemAvatar>
                <ListItemText primary={season.label} />
              </MenuItem>
            );
          })}
          <ListSubheader>Golden Bachelorette</ListSubheader>
          {goldenBacheloretteSeasons.map((seasonId) => {
            const season = seasonsById[seasonId];

            return (
              <MenuItem
                key={season.id}
                value={season.id}
              >
                <ListItemAvatar>
                  <Avatar
                    src={`/images/${season.imageUrl}`}
                  />
                </ListItemAvatar>
                <ListItemText primary={season.label} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSeason: selectCurrentSeason(state),
  };
}

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withRouter,
  withWidth(),
)(SeasonSelector);
